/*==========================================================================
Contact
==========================================================================*/
.contact__area {
  &-title {
    p {
      max-width: 460px;
    }
  }
  &-info {
    margin-top: 30px;
    &-item {
      display: flex;
      margin-bottom: 25px;
      gap: 25px;
      &-icon {
        margin-top: 8px;
        i {
          color: var(--primary-color);
          font-size: 30px;
        }
      }
      &-content {
        span {
          color: var(--color-1);
          display: inline-block;
          margin-bottom: 5px;
        }
        h6 {
          max-width: 240px;
          a {
            transition: all 0.4s ease-out 0s;
            &:hover {
              color: var(--primary-color);
            }
          }
        }
      }
      &:last-child {
        margin: 0;
      }
    }
  }
  &-social {
    margin-top: 40px;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        display: inline-block;
        margin-right: 10px;
        &:last-child {
          margin: 0;
        }
        a {
          i {
            display: inline-block;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border: 1px solid var(--color-10);
            border-radius: 50%;
            color: var(--color-8);
            transition: all 0.4s ease-out 0s;
            &:hover {
              color: var(--white);
              background: var(--primary-color);
              border-color: var(--primary-color);
            }
          }
        }
      }
    }
  }
  &-form {
    &-item {
      position: relative;
      i {
        position: absolute;
        left: 20px;
        top: 23px;
        color: var(--primary-color);
      }
      input {
        font-size: 14px;
        background: transparent;
        padding-left: 45px;
      }
      textarea {
        height: 220px;
        font-size: 14px;
        background: transparent;
        padding-left: 45px;
      }
      .theme-btn {
        i {
          position: relative;
          top: initial;
          left: initial;
          color: var(--white);
          transition: all 0.4s ease-out 0s;
        }
      }
    }
  }
  &-map iframe {
    width: 100%;
    height: 570px;
    display: block;
  }
}
