/*==========================================================================
Check
==========================================================================*/
.check__area {
    background: var(--heading-color);
    display: flex;
    justify-content: space-between;    
    &-item {
        padding: 24px 40px;
        width: 100%;
        border-right: 1px solid var(--color-7);
        p {
            color: var(--color-2);
            font-family: var(--heading-font);
            font-weight: 700;
            margin: 0;
            input {
                color: var(--primary-color);
                background: transparent;
                border: 0;
                font-family: var(--heading-font);
                font-weight: 600;
                font-size: 14px;
                cursor: pointer;
                line-height: 24px;
                padding: 0;
                height: initial;
                &:focus {
                    color: var(--primary-color);
                }
            }
        }
        &-room {
            .nice-select {
                color: var(--primary-color);
                font-family: var(--heading-font);
                font-weight: 600;
                font-size: 14px;
                border: 0;
                padding: 0;
                background: transparent;
                width: 100%;
                height: initial;
                line-height: initial;
                top: 5px;                
                &::after {
                    width: 8px;
                    height: 8px;
                    right: 4px;
                    margin-top: -3px;
                    border-bottom: 2px solid var(--primary-color);
                    border-right: 2px solid var(--primary-color);
                    transition: all 0.4s ease-out 0s;                    
                }
                .list {
                    padding: 0;
                    margin: 0;
                    margin-top: 27px;
                    border-radius: 0;
                    width: 100%;
                    transition: all 0.4s ease-out 0s;
                    li {
                        color: var(--heading-color);
                        font-weight: 600;
                    }
                    .option.selected.focus {
                        color: var(--primary-color);
                    }
                }
            }
        }
        &.button {
            padding: 0;
            border: 0;
            .theme-btn {
                width: 100%;
                height: 100%;
                justify-content: center;
                &::before {
                    background: var(--white);
                }
                &::after {
                    background: var(--white);
                }
                &:hover {
                    color: var(--heading-color);
                }
            }
        }
    }
    &.two {
        background: var(--white);
        .check__area-item {
            border-color: var(--color-5);
            &.button {
                .theme-btn {
                    &::before {
                        background: var(--heading-color);
                    }
                    &::after {
                        background: var(--heading-color);
                    }
                    &:hover {
                        color: var(--white);
                    }
                }
            }
        }
    }
}
@media (max-width: 991px) {
    .check__area {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 575px) {
    .check__area {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        &-item {
            &-room {
                .nice-select {
                    .list {
                        margin-top: 19px;
                    }
                }
            }
        }
        &.two {
            .check__area-item {
                border: 0;
                border-bottom: 1px solid;
                border-color: var(--color-5);
            }
        }
    }
}