/*==========================================================================
Page
========================================================================== */
.page__banner {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	z-index: 1;
    padding-top: 235px;
    padding-bottom: 125px;
    &::after {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        background: var(--heading-color);
        opacity: 0.8;
    }
    &-title {
        text-align: center;
        h1 {
            color: var(--white);
            font-weight: 500;
            font-size: 45px;
            line-height: 55px;
            margin-bottom: 10px;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                display: inline-block;
                font-family: var(--heading-font);
                color: var(--primary-color);
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                span {
                    color: var(--white);
                    display: inline-block;
                    margin: 0 10px 0 5px;
                }
                a {
                    color: var(--white);
                    transition: all 0.4s ease-in-out; 
                    &:hover {
                        color: var(--primary-color);
                    }
                }
            }
        }
    }
}
@media (max-width: 359px) {
    .page__banner {
        &-title {
            h1 {
                font-size: 42px;
                line-height: 52px;
            }
        }
    }
}