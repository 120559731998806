
/*==========================================================================
Instagram
==========================================================================*/
.instagram__area {
    padding: 0 25px;
    margin-bottom: 10px;
    &-item {
        position: relative;
        z-index: 1;
        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 30%;
            background: var(--heading-color);
            opacity: 0;
            z-index: 0;
            transition: all 0.4s ease-out 0s;
        }
        img {
            width: 100%;
        }
        &-icon {
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
            z-index: 1;
            opacity: 0;
            transition: all 0.4s ease-out 0s;
            a {
                i {
                    color: var(--white);
                    font-size: 45px;
                    line-height: 55px;
                }
            }
        }
        &:hover {
            &::after {
                opacity: 0.6;
                height: 100%;
            }
            .instagram__area-item-icon {
                opacity: 1;
                top: 50%;
            }
        }         
    }
}
@media (max-width: 767px) {
    .instagram__area {
        padding: 0 5px;
    }
}